import React from 'react';
import cx from 'classnames';
import Link from 'components/Link';
import { LinkProps } from 'react-router-dom';

const TitleLink = ({
  className,
  title,
  to,
}: {
  className?: string;
  title?: string;
  to: LinkProps['to'];
}) => (
  <Link className={cx(className, { 'font-italic': !title })} to={to}>
    {title || 'No Title'}
  </Link>
);

export default TitleLink;
