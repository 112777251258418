import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
  AsyncPaginate,
  AsyncPaginateProps,
  LoadOptions as LoadOptionsOriginal,
  ReduceOptions as ReduceOptionsOriginal,
} from 'react-select-async-paginate';
import {
  ActionMeta,
  components,
  GroupBase,
  StylesConfig,
  Theme,
} from 'react-select';
import variables from 'scss/1-settings/colors.scss';
import '../SelectField/SelectField.scss';
import InputFieldErrorMessage from 'components/InputFieldErrorMessage';
import { SelectFieldAsyncOptionObject } from '../SelectFieldAsync/SelectFieldAsync';
import SelectFieldCustomClearIndicator from '../SelectFieldCustomClearIndicator/SelectFieldCustomClearIndicator';

import './SelectFieldAsyncPaginate.scss';

type PageInfo = {
  page: number;
};

export type LoadOptions<
  Option extends SelectFieldAsyncOptionObject = SelectFieldAsyncOptionObject,
  Additional extends object = PageInfo,
  Group extends GroupBase<Option> = GroupBase<Option>
> = (showAll: boolean) => LoadOptionsOriginal<Option, Group, Additional>;

export type ReduceOptions<
  Option extends SelectFieldAsyncOptionObject = SelectFieldAsyncOptionObject,
  Group extends GroupBase<Option> = GroupBase<Option>,
  Additional extends object = PageInfo
> = ReduceOptionsOriginal<Option, Group, Additional>;

export interface SelectFieldAsyncPaginateProps<
  Option extends SelectFieldAsyncOptionObject,
  Additional extends object = PageInfo,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends Omit<
    AsyncPaginateProps<Option, Group, Additional, IsMulti>,
    'loadOptions'
  > {
  id?: string;
  isTouched?: boolean;
  errors?: string;
  selectFieldId?: string;
  isLoading?: boolean;
  selectedValue?: AsyncPaginateProps<
    Option,
    Group,
    Additional,
    IsMulti
  >['value'];
  loadOptions: LoadOptions<Option, Additional, Group>;
  dataTestId?: string;
  disabledOption?: string;
  clearInputValueOnFocus?: boolean;
}

const Input = (props: any) => <components.Input {...props} isHidden={false} />;

const reactSelectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0.2,
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const customStyles: StylesConfig = {
  input: provided => ({
    ...provided,
    input: {
      height: '100% !important',
      visibility: 'visible',
    },
  }),
  option: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? variables['gray-100'] : 'inherit',
  }),
  groupHeading: styles => ({
    ...styles,
    textTransform: 'none',
    fontSize: '0.7rem',
    paddingBottom: '4px',
  }),
};

const SelectFieldAsyncPaginate = <
  Option extends SelectFieldAsyncOptionObject,
  Additional extends object,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectFieldAsyncPaginateProps<Option, Additional, IsMulti, Group> & {
    selectedOptionRedirectUrl?: string;
  }
) => {
  const {
    isTouched,
    errors,
    selectFieldId,
    isClearable = true,
    isMulti,
    classNamePrefix = 'rs',
    controlShouldRenderValue = false,
    value,
    loadOptions,
    onChange,
    dataTestId = '',
    disabledOption,
    clearInputValueOnFocus = false,
    selectedOptionRedirectUrl: redirectUrl,
  } = props;
  const [inputValue, setInputValue] = useState((value as any)?.label);
  const [selectedValues, setSelectedValues] = useState<any>(value);
  useEffect(() => {
    setInputValue((value as any)?.label);
    setSelectedValues(value);
  }, [value]);
  const [showAllOptions, setShowAllOptions] = useState(false);

  const handleFocus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (clearInputValueOnFocus) {
        setInputValue('');
      }
      event?.target?.select();
      setShowAllOptions(true);
    },
    [clearInputValueOnFocus]
  );

  const onInputChange = useCallback(
    (input: string, { action }: { action: string }) => {
      // onBlur => setInputValue to last selected value
      if (action === 'input-blur') {
        if (isMulti) return;
        setInputValue(selectedValues ? selectedValues.label : '');
      }

      // onInputChange => update inputValue
      else if (action === 'input-change') {
        setInputValue(input);
      }
      setShowAllOptions(false);
    },
    [isMulti, selectedValues]
  );

  const handleChange = useCallback(
    (option: any, actionMeta: ActionMeta<Option>) => {
      if (actionMeta.action === 'pop-value') return;
      onChange?.(option, actionMeta);
      setInputValue(option ? option.label : '');
      setSelectedValues(option);
    },
    [onChange]
  );

  return (
    <div
      data-testid={dataTestId || selectFieldId}
      className="select-field-async-paginate"
    >
      <AsyncPaginate
        {...props}
        theme={reactSelectTheme}
        // casting to `any` so we don't have to deal with generic parameters.
        // they are useless here anyway - we're just settings <input>'s height
        styles={customStyles as any}
        value={selectedValues}
        components={{
          Input,
          ClearIndicator: clearIndicatorProps =>
            SelectFieldCustomClearIndicator({
              ...clearIndicatorProps,
              redirectUrl,
            }),
        }}
        onFocus={handleFocus}
        onInputChange={onInputChange}
        onChange={handleChange}
        inputValue={inputValue}
        isClearable={isClearable}
        classNamePrefix={classNamePrefix}
        loadOptions={loadOptions(showAllOptions)}
        controlShouldRenderValue={controlShouldRenderValue}
        cacheUniqs={[selectedValues, loadOptions]}
        onMenuClose={() => setShowAllOptions(true)}
        isOptionDisabled={option => option.value === disabledOption}
      />
      {isTouched && errors ? (
        <InputFieldErrorMessage
          dataTestId={`${dataTestId || selectFieldId}__selectInputFormFeedback`}
        >
          {errors}
        </InputFieldErrorMessage>
      ) : null}
    </div>
  );
};

export default SelectFieldAsyncPaginate;
