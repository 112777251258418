import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowLeft,
  faLongArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import { SelectFieldv2 } from 'components';

import './Pagination.scss';

interface IPagination {
  tableId?: string;
  pageIndex: number;
  pageSize: number;
  gotoPageInputHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurHandler: (e: React.FocusEvent<HTMLInputElement>) => void;
  pageOptions: number[];
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  itemName: string;
  hideDisplayedElementsDropdown?: boolean;
  hasCustomLastBodyItem?: boolean;
  canPreviousPage: boolean;
  canNextPage: boolean;
  nextPageClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  previousPageClickHandler: (e: React.MouseEvent<HTMLButtonElement>) => void;
  setPageSizeSelectHandler: (
    tableSize: {
      value: string;
      label: string;
    } | null
  ) => void;
  displayItemsNumberOptions: number[];
  goToPageInputRef?: React.RefObject<HTMLInputElement>;
}

const Pagination = ({
  goToPageInputRef,
  hideDisplayedElementsDropdown,
  hasCustomLastBodyItem,
  setPageSizeSelectHandler,
  displayItemsNumberOptions,
  canPreviousPage,
  canNextPage,
  nextPageClickHandler,
  previousPageClickHandler,
  tableId = 'table',
  pageIndex,
  pageOptions,
  pageSize,
  gotoPageInputHandler,
  onBlurHandler,
  handleKeyDown,
  handleOnInput,
  itemName,
}: IPagination) => (
  <div className="pagination" data-testid={`${tableId}__paginationContainer`}>
    <div className="choose-page">
      <span>Page </span>
      <input
        className="form-control page-jump"
        type="number"
        data-testid={`${tableId}__goToPageInput`}
        defaultValue={pageIndex + 1}
        key={pageIndex + 1}
        onChange={gotoPageInputHandler}
        onBlur={onBlurHandler}
        min={1}
        max={pageOptions.length}
        ref={goToPageInputRef}
        onKeyDown={handleKeyDown}
        onInput={handleOnInput}
        onFocus={e => e.target.select()}
      />
      <span data-testid={`${tableId}__pageNumeration`}>
        of {pageOptions.length || 1}
      </span>
    </div>

    <div>
      {hideDisplayedElementsDropdown ? null : (
        <SelectFieldv2
          className="menuPlacementTop"
          onChange={setPageSizeSelectHandler}
          id="table-size"
          isClearable={false}
          options={displayItemsNumberOptions.map(option => ({
            value: option.toString(),
            label: `${hasCustomLastBodyItem ? option + 1 : option} ${itemName}`,
          }))}
          defaultValue={pageSize.toString()}
          isMulti={false}
          isSearchable={false}
          menuPlacement="top"
        />
      )}
    </div>
    <div className="btn-group h-100" role="group">
      <button
        className="btn btn-light border"
        type="button"
        data-testid={`${tableId}__goToPreviousPageBtn`}
        onClick={previousPageClickHandler}
        disabled={!canPreviousPage}
      >
        <FontAwesomeIcon icon={faLongArrowLeft} size="xs" />
      </button>
      <button
        className="btn btn-light border"
        type="button"
        data-testid={`${tableId}__goToNextPageBtn`}
        onClick={nextPageClickHandler}
        disabled={!canNextPage}
      >
        <FontAwesomeIcon icon={faLongArrowRight} size="xs" />
      </button>
    </div>
  </div>
);

export default Pagination;
