import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorPage, NotFound } from 'infrastructure/error';
import AccountV2, {
  ForgotPassword,
  ResetPassword,
  PasswordResetConfirmation,
} from 'slices/AccountV2/Loadable';
import { Preview as StoryPreview } from 'slices/Story/Loadable';
import { Preview as InterstitialAdPreview } from 'slices/InterstitialAdv2/Loadable';
import Dashboard from './Dashboard';

const AppRouter = () => (
  <div className="wrapper theme-lightondark d-flex flex-column justify-content-center min-vh-100">
    <Switch>
      <Route exact path="/login" component={AccountV2} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route
        path="/password-reset-confirmation"
        component={PasswordResetConfirmation}
      />
      <Route path="/error" component={ErrorPage} />
      <Route path="/500" component={ErrorPage} />
      <Route path="/404" component={NotFound} />
      <Route path="/stories/:id/preview/public" component={StoryPreview} />
      <Route
        path="/interstitialAd/:id/preview/public"
        component={InterstitialAdPreview}
      />
      <Route path="/" component={Dashboard} />
    </Switch>
  </div>
);

export default AppRouter;
