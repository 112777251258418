import React, { useEffect, useRef, useState } from 'react';
import Select, {
  ActionMeta,
  Theme,
  GroupBase,
  OnChangeValue,
  SelectInstance,
} from 'react-select';
import variables from 'scss/1-settings/colors.scss';
import cx from 'classnames';
import { mergeRefs } from 'shared/helpers/mergeRefs';
import InputFieldErrorMessage from 'components/InputFieldErrorMessage';
import customStyles from './SelectFieldCustomStyles';
import useCustomSelectFieldValue from './useCustomSelectFieldValue';
import SelectFieldCustomClearIndicator from '../SelectFieldCustomClearIndicator/SelectFieldCustomClearIndicator';
import type {
  SelectFieldProps,
  SelectFieldOptionObject,
  SelectFieldOptionsArray,
} from './types';

import './SelectField.scss';

const reactSelectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0.2,
  borderColor: 'border-color',
  colors: {
    ...theme.colors,
    primary: variables.success,
    primary75: variables['success-75'],
    primary50: variables['success-50'],
    primary25: variables['success-25'],
    danger: variables.danger,
    dangerLight: variables['danger-lighter'],
  },
});

const SelectField = <
  Option extends SelectFieldOptionObject,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  id,
  onChange,
  options,
  placeholder,
  isMulti,
  onBlur,
  isTouched = false,
  errors,
  selectFieldId,
  isClearable = true,
  isSearchable = true,
  menuPosition,
  defaultValue,
  displayValue,
  isDisabled = false,
  customRef,
  menuPlacement = 'auto',
  controlledValue,
  hideSelectedOptions,
  className,
  dataTestId = '',
  variant = 'field',
  isOptionDisabled,
  showDropdownIndicator,
  readOnly,
}: SelectFieldProps<Option, IsMulti, Group>) => {
  const [selectedValue, setSelectedValue] =
    useState<OnChangeValue<Option, IsMulti>>();

  const selectRef = useRef<SelectInstance<Option, IsMulti, Group>>(null);

  const customSelectFieldProps = useCustomSelectFieldValue<
    Option,
    IsMulti,
    Group
  >({
    displayValue,
    selectRef,
  });

  const onFieldChange = (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>
  ) => {
    onChange?.(newValue, actionMeta);
    setSelectedValue(newValue);
  };
  useEffect(() => {
    function isOption(value: Option | Group): value is Option {
      return 'value' in value;
    }
    if ((!defaultValue && defaultValue !== '') || !options) return;
    const defaultOption = options
      .filter(isOption)
      .find(option => option.value === defaultValue);

    setSelectedValue((isMulti ? [defaultOption] : defaultOption) as any);
  }, [defaultValue, options, isMulti]);

  const isInline = variant.startsWith('inline');

  const withCheckboxes = isMulti && !hideSelectedOptions;

  const isClearableComponentVisible =
    (!!controlledValue || !!selectedValue) && isClearable && !isDisabled;

  const onlyOptionIsSelected =
    options?.length === 1 && selectedValue === options[0];

  return (
    <div
      className={cx('SelectField', {
        inline: isInline,
      })}
      data-testid={dataTestId || selectFieldId}
    >
      <Select
        {...customSelectFieldProps}
        classNamePrefix={isInline ? 'inlineRs' : 'rs'}
        className={cx(className, {
          alternative: isInline,
          withCustomValue: !!displayValue,
          withCheckboxes: isMulti && !hideSelectedOptions,
          isInvalid: !!errors && isTouched,
          readOnly,
        })}
        closeMenuOnSelect={!withCheckboxes}
        inputId={id}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        onChange={onFieldChange}
        onBlur={onBlur}
        theme={reactSelectTheme}
        placeholder={placeholder}
        isMulti={isMulti}
        menuPosition={menuPosition}
        value={controlledValue !== undefined ? controlledValue : selectedValue}
        isDisabled={
          readOnly || isDisabled || (onlyOptionIsSelected && !isClearable)
        }
        styles={
          isInline
            ? customStyles<Option, IsMulti>(
                variant === 'inlineLink',
                isMulti,
                isClearableComponentVisible,
                showDropdownIndicator
              )
            : undefined
        }
        ref={mergeRefs([selectRef, customRef]) as any}
        menuPlacement={menuPlacement}
        hideSelectedOptions={hideSelectedOptions}
        components={{
          ...customSelectFieldProps.components,
          ClearIndicator: SelectFieldCustomClearIndicator,
        }}
        isOptionDisabled={isOptionDisabled}
      />
      {isTouched && errors ? (
        <InputFieldErrorMessage
          dataTestId={`${dataTestId || selectFieldId}__selectInputFormFeedback`}
        >
          {errors}
        </InputFieldErrorMessage>
      ) : null}
    </div>
  );
};

export default SelectField;

export { SelectFieldProps, SelectFieldOptionObject, SelectFieldOptionsArray };
