import React from 'react';
import useApi from 'infrastructure/api/useApi';
import { useQuery } from '@tanstack/react-query';
import { menuEndpoint } from 'infrastructure/api/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface MenuItem {
  cssClass: string | null;
  customData: { type: string; iconPackage: 'string' };
  displayName: string;
  elementId: string;
  icon: string;
  isDisabled: boolean;
  isLeaf: boolean;
  items: Array<MenuItem>;
  name: string;
  order: number;
  target: string | null;
  url: string;
}

const addSidebarLinks = (menuItems: Array<MenuItem>) => {
  const items = menuItems.map(menuItem => ({
    ...menuItem,
    ...menuItem.customData,
    icon: (
      <FontAwesomeIcon
        icon={[
          (menuItem.customData && (menuItem.customData.iconPackage as any)) ||
            'fal',
          menuItem.icon as any,
        ]}
      />
    ),
  }));

  return items;
};

const menuKey = ['menu'];

const useGetMenu = () => {
  const { requestApi } = useApi();

  return useQuery({
    queryKey: menuKey,
    queryFn: () =>
      requestApi(menuEndpoint, { resource: '' }).then(({ data }) =>
        addSidebarLinks(data.items)
      ),
  });
};

export default useGetMenu;
