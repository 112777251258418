import React, { useCallback } from 'react';
import { Label } from 'reactstrap';
import SelectField from 'components/v2/SelectField/SelectField';
import type { SingleValue } from 'react-select';
import StoryStatusOptions, { StatusValueOptionType } from './StatusOptions';

import './StatusSelector.scss';

export interface StatusSelectorProps {
  isDisabled: boolean;
  selectedStatus: string;
  setStatus: (status: StatusValueOptionType) => void;
}

const StatusSelector: React.FC<StatusSelectorProps> = ({
  isDisabled,
  selectedStatus,
  setStatus,
}) => {
  const handleOnChange = useCallback(
    (status: SingleValue<{ label: string; value: StatusValueOptionType }>) => {
      if (status) setStatus(status.value);
    },
    [setStatus]
  );

  return (
    <div
      className="StatusSelector__container"
      data-testid="StatusSelector__container"
    >
      <Label>Status</Label>
      <SelectField
        onChange={handleOnChange}
        isClearable={false}
        isSearchable={false}
        options={StoryStatusOptions}
        isMulti={false}
        isDisabled={isDisabled}
        controlledValue={StoryStatusOptions.find(
          option => option.value === selectedStatus
        )}
        hideSelectedOptions
      />
    </div>
  );
};

export default StatusSelector;
