import React from 'react';
import { createRoot } from 'react-dom/client';

// This needs to be the first stylesheet imported so component stylesheets
// will overwrite it without having to add extra specificity.
import 'scss/app.scss';

import startApp from 'infrastructure/start';
import { errorHandler, ErrorPage } from 'infrastructure/error';

import App from 'containers/App';

import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'production') {
  const onerror = errorHandler();
  global.onerror = onerror;
}

const container = document.getElementById('root');
const root = createRoot(container);

startApp(
  () => root.render(<App tab="home" />),
  () => root.render(<ErrorPage canRetry />)
);

serviceWorker.unregister();
