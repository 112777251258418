import React, { PropsWithChildren } from 'react';
import { Col, type ColProps } from 'reactstrap';
import cx from 'classnames';

import './ActionsContainer.scss';

interface IActionsContainer {
  className?: string;
}

const ActionsContainer: React.FC<
  PropsWithChildren<ColProps & IActionsContainer>
> = ({ children, className, xs = 12, lg = 4 }) => (
  <Col xs={xs} lg={lg} className={cx('actions-container', className)}>
    {children}
  </Col>
);
export default ActionsContainer;
