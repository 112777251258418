import React, { CSSProperties, MouseEvent, useCallback, useMemo } from 'react';
import Pie, { type PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { Annotation, Connector, Label } from '@visx/annotation';
import { ParentSizeModern } from '@visx/responsive';
import { Group } from '@visx/group';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { toReadablePercentage } from 'slices/Story/componentsV2/StoryAnalytics/helpers';
import { Col, Row } from 'reactstrap';
import Loading from 'components/Loading/Loading';
import variables from 'scss/1-settings/colors.scss';
import PieChartTooltip from './PieChartTooltip';
import PieChartLegend from './PieChartLegend';

import './PieChart.scss';

const getAnnotationPointCoordinates = (
  centroidX: number,
  centroidY: number,
  chartWidth: number
) => {
  const targetLabelOffset = (chartWidth / 2) * 0.4;
  // Calculating Normalized Direction Vector Between Point [0,0] and [centroidX, centroidY]
  const dx =
    centroidX / Math.sqrt(centroidX * centroidX + centroidY * centroidY);
  const dy =
    centroidY / Math.sqrt(centroidX * centroidX + centroidY * centroidY);
  // Calculating Point Coordinates Based on Offset Distance
  const finalX = dx * targetLabelOffset;
  const finalY = dy * targetLabelOffset;
  return [finalX, finalY];
};

const INITIAL_SUM_ZERO = 0;

const pieChartDefaultColors = [
  variables['chart-color-1'],
  variables['chart-color-2'],
  variables['chart-color-3'],
  variables['chart-color-4'],
  variables['chart-color-5'],
  variables['chart-color-6'],
  variables['chart-color-7'],
  variables['chart-color-8'],
];

export type PieChartData = {
  name?: string;
  value: number;
  fill?: string;
  imageUrl?: string;
};

export interface PieChartProps {
  data: Array<PieChartData>;
  loading?: boolean;
  showLegend?: boolean;
  enableTooltip?: boolean;
  enableAnnotation?: boolean;
  endAngle?: number;
  innerRadius?: number; // should be %, up to 100 value
  outerRadius?: number;
  startAngle?: number;
  sortData?: boolean;
  sizeStyles?: CSSProperties;
}

const PieChart: React.FC<PieChartProps> = ({
  data,
  loading,
  showLegend,
  enableTooltip,
  enableAnnotation,
  endAngle,
  innerRadius,
  outerRadius,
  startAngle,
  sortData,
  sizeStyles,
}) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    name: string;
    fill: string;
    value: string | number;
    percent: string | number;
  }>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    scroll: true,
  });

  const handleMouseOver = useCallback(
    (
      event: MouseEvent<SVGGElement>,
      datum: PieArcDatum<{
        fill: any;
        percent: number;
        name?: string;
        value: number;
        imageUrl?: string;
      }>
    ) => {
      const coords = localPoint(
        (event.target as SVGElement).ownerSVGElement as SVGGElement,
        event
      );
      showTooltip({
        tooltipLeft: coords?.x,
        tooltipTop: coords?.y,
        tooltipData: {
          name: datum.data.name || '',
          fill: datum.data.fill,
          value: datum.data.value,
          percent: datum.data.percent,
        },
      });
    },
    [showTooltip]
  );

  const chartData = useMemo(() => {
    const dataValueSum = data
      .map(dataItem => dataItem.value)
      .reduce((prev, curr) => prev + curr, INITIAL_SUM_ZERO);
    const filteredData = data.filter(dataItem => dataItem.value > 0);
    if (sortData) filteredData.sort((a, b) => (a.value < b.value ? 1 : -1));
    return filteredData.map((dataItem, index) => ({
      ...dataItem,
      fill: dataItem.fill || pieChartDefaultColors[index],
      percent: toReadablePercentage(dataItem.value, dataValueSum),
    }));
  }, [data, sortData]);

  const radiusDivisor = enableAnnotation ? 3 : 2;

  return (
    <Row className="chart pie-chart-wrapper">
      <Loading visible={loading} maskContainer />
      <Col md={6}>{showLegend && <PieChartLegend dataItems={chartData} />}</Col>
      <Col md={showLegend ? 6 : 12}>
        <ParentSizeModern parentSizeStyles={sizeStyles}>
          {({ width, height }) => {
            const defaultOuterRadius = Math.min(width, height) / radiusDivisor;
            const defaultInnerRadius =
              innerRadius &&
              ((Math.min(width, height) / radiusDivisor) * innerRadius) / 100;
            const piePosition = width / 2;

            return (
              <>
                <svg ref={containerRef} width={width} height={width}>
                  <Group top={piePosition} left={piePosition}>
                    <Pie
                      data={chartData}
                      pieValue={dataElement => dataElement.value}
                      outerRadius={outerRadius || defaultOuterRadius}
                      endAngle={endAngle}
                      startAngle={startAngle}
                      innerRadius={defaultInnerRadius}
                      pieSort={null}
                    >
                      {pie =>
                        pie.arcs.map(arc => {
                          const [centroidX, centroidY] = pie.path.centroid(arc);
                          const [dx, dy] = getAnnotationPointCoordinates(
                            centroidX,
                            centroidY,
                            width
                          );

                          return (
                            <React.Fragment
                              key={`${arc.data.name}-${arc.data.value}`}
                            >
                              <g
                                key={arc.value}
                                onMouseOver={event =>
                                  handleMouseOver(event, arc)
                                }
                                onMouseOut={hideTooltip}
                              >
                                <path
                                  d={pie.path(arc) || ''}
                                  fill={arc.data.fill}
                                />
                              </g>
                              {enableAnnotation && (
                                <Annotation
                                  x={centroidX}
                                  y={centroidY}
                                  dx={dx}
                                  dy={dy}
                                >
                                  <Label
                                    showAnchorLine={false}
                                    anchorLineStroke={
                                      variables['black-tertiary']
                                    }
                                    showBackground={false}
                                    title={`${arc.data.percent}%`}
                                    titleFontWeight={200}
                                    fontColor={variables['black-tertiary']}
                                    titleFontSize="0.75em"
                                  />
                                  <Connector
                                    stroke={arc.data.fill}
                                    type="line"
                                  />
                                </Annotation>
                              )}
                            </React.Fragment>
                          );
                        })
                      }
                    </Pie>
                  </Group>
                </svg>
                {enableTooltip && tooltipOpen && (
                  <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                    <PieChartTooltip tooltipData={tooltipData} />
                  </TooltipInPortal>
                )}
              </>
            );
          }}
        </ParentSizeModern>
      </Col>
    </Row>
  );
};

export default PieChart;
