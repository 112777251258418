import React, {
  type PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';

import './InputFieldErrorMessage.scss';

interface InputFieldErrorMessageProps {
  className?: string;
  dataTestId?: string;
  isInfo?: boolean;
}

const InputFieldErrorMessage: React.FC<
  PropsWithChildren<InputFieldErrorMessageProps>
> = ({ children, className, dataTestId, isInfo }) => {
  const errorRef = useRef<HTMLElement>(null);
  const [lineCount, setLineCount] = useState(0);

  useEffect(() => {
    if (!children) return;

    const calculateLines = () => {
      if (!errorRef.current) return;
      const { clientHeight } = errorRef.current;
      const { lineHeight } = window.getComputedStyle(errorRef.current);
      setLineCount(Math.floor(clientHeight / parseInt(lineHeight, 10)));
    };

    calculateLines();
    window.addEventListener('resize', calculateLines);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', calculateLines);
    };
  }, [children]);

  return (
    <small
      data-testid={dataTestId || 'text-field-error-test-id'}
      className={cx(
        'validation form__form-group-error error-message invalid-feedback',
        {
          'position-relative': lineCount > 1,
          'info-message': isInfo,
        },
        className
      )}
      ref={errorRef}
    >
      {children}
    </small>
  );
};

export default InputFieldErrorMessage;
