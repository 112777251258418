import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CellProps,
  Row,
  SortingRule,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table';
import type { AxiosResponse } from 'axios';
import DataTablePagination from 'components/v2/DataTable/DataTablePagination';
import DataTableBody from 'components/v2/DataTable/DataTableBody';
import DataTableHeader from 'components/v2/DataTable/DataTableHeader';
import URL_PARAMS from 'components/v2/DataTable/data/urlParams';
import getURLParam from 'components/v2/DataTable/helpers/getURLParam';
import { DataTableTopBar, Loading, UIMessage } from 'components';
import NoResultsImage from 'img/blank-states/search.svg';
import classnames from 'classnames';
import usePrevious from 'infrastructure/hooks/usePrevious';
import getInitialSorting from 'components/v2/DataTable/helpers/getInitialSorting';
import getSortingParamsFromURL from 'components/v2/DataTable/helpers/getSortingParamsFromURL';
import useURLParams from 'components/v2/DataTable/helpers/useURLParams';
import {
  getLocalFilter,
  saveLocalFilter,
} from 'components/v2/DataTable/helpers/localFilters';
import { DisplayOrderChangeType } from 'slices/StudioAssets/Templates/types';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useDebouncedCallback } from 'use-debounce';
import { useLocation } from 'react-router';
import type { LinkProps } from 'react-router-dom';
import type { TopBarConfig, TopBarProps } from './TopBar';
import {
  ButtonDown,
  ButtonUp,
  CustomButton,
  DownloadButton,
  EditButton,
  GetLatestButton,
  RemoveButton,
} from './Buttons/actionButtons';

import './DataTable.scss';

export interface DataTableColumn {
  header: string | JSX.Element;
  accessor: string;
}

export interface PaginatedListResponse<Data> {
  items: Array<Data>;
  totalCount: number;
}

export type SortedColumn = { id?: string; desc?: boolean };

export type DataTableFetchProps = {
  pageSize: number;
  pageIndex: number;
  sortedColumn?: SortedColumn;
};

export type DataTableFetchFunction<Data extends object = {}> = (args: {
  pageSize: number;
  pageIndex: number;
  sortedColumn?: SortedColumn;
}) => Promise<AxiosResponse<PaginatedListResponse<Data>>>;

export interface DataTableProps<Data extends object> {
  // Additional class name for the table
  className?: string;
  // ID for e.g. testing purposes
  tableId: string;
  // Structure interface of columns in the table
  columns: Array<DataTableColumn>;
  // Array of objects with data
  defaultData?: Array<Data>;
  // Promise returning the data that should be loaded
  fetchData?: DataTableFetchFunction<Data>;
  // Component that will be upon the table, e. g. inputs, selects, search bar etc. Optional, defaults to `components/v2/DataTable/TopBar`
  topBar?: React.FC<TopBarProps>;
  // Config for `topBar` component
  topBarConfig?: TopBarConfig;
  getRowEditPath?: (row: Row<Data>) => LinkProps['to'];
  disableRowEdit?: (row: Row<Data>) => void;
  onRowRemove?: (row: Row<Data>) => void;
  disableRowRemove?: (row: Row<Data>) => void;
  onReorder?: (reorderData: DisplayOrderChangeType) => void;
  onRowDownload?: (row: Row<Data>) => void;
  onRowDuplicate?: (row: Row<Data>) => void;
  customButton?: {
    text?: string;
    icon?: IconDefinition;
    iconAfter?: IconDefinition;
    className?: string;
    onCustomButton: (row: Row<Data>) => void;
  };
  onRowGetLatest?: (row: Row<Data>) => void;
  customBodyItem?: (row: Row<Data> | Record<'row', Row<Data>>) => JSX.Element;
  customLastBodyItem?: () => JSX.Element;
  isDeleteRequestPending?: boolean;
  defaultSortBy?: SortingRule<{}>[];
  noActionsColumn?: boolean;
  noTableHeader?: boolean;
  /**
   * Boolean value: table uses its default 6 column grid
   * String value: table uses custom column grid (Currently 'grid-4' available)
   */
  gridLayout?: boolean | string;
  defaultPageSize?: number;
  displayItemsNumberOptions?: number[];
  itemName?: string;
  enableReordering?: boolean;
  defaultPageCount?: number;
  disableURLParams?: boolean;
  hideDisplayedElementsDropdown?: boolean;
  customBlankPage?: ReactElement;
  hidePagination?: boolean;
  areTableDependeciesLoading?: boolean;
  dontGrowActionsColumn?: boolean;
}

const ACTIONS_COLUMN_ACCESSOR = 'action';

const generateColumns = <Data extends object>(
  originColumns: DataTableProps<Data>['columns'],
  onRowRemove: DataTableProps<Data>['onRowRemove'],
  disableRowRemove: DataTableProps<Data>['disableRowRemove'],
  getRowEditPath: DataTableProps<Data>['getRowEditPath'],
  disableRowEdit: DataTableProps<Data>['disableRowEdit'],
  onReorder: DataTableProps<Data>['onReorder'],
  onRowDownload: DataTableProps<Data>['onRowDownload'],
  customButton: DataTableProps<Data>['customButton'],
  onRowGetLatest: DataTableProps<Data>['onRowGetLatest'],
  tableId: DataTableProps<Data>['tableId'],
  firstElementId?: string,
  lastElementId?: string,
  disableReorder?: boolean,
  dontGrowActionsColumn?: boolean
) => {
  const doesActionsExists = originColumns.find(
    column => column.accessor === ACTIONS_COLUMN_ACCESSOR
  );
  if (doesActionsExists) {
    return originColumns;
  }

  return [
    ...originColumns,
    {
      header: 'Actions',
      accessor: ACTIONS_COLUMN_ACCESSOR,
      disableSortBy: true,
      className: 'actions',
      minWidth: onReorder ? 225 : 160,
      dontGrow: dontGrowActionsColumn,
      Cell: ({ row }: CellProps<Data>) => (
        <div className="action-cell">
          {customButton && (
            <CustomButton row={row} tableId={tableId} {...customButton} />
          )}
          {onRowDownload && (
            <DownloadButton
              onRowDownload={onRowDownload}
              row={row}
              tableId={tableId}
            />
          )}
          {onRowRemove && (
            <RemoveButton
              onRowRemove={onRowRemove}
              row={row}
              tableId={tableId}
              disableRowRemove={disableRowRemove}
            />
          )}
          {getRowEditPath && (
            <EditButton
              getRowEditPath={getRowEditPath}
              row={row}
              tableId={tableId}
              disableRowEdit={disableRowEdit}
            />
          )}
          {onReorder && !disableReorder && (
            <>
              <ButtonUp
                onReorder={onReorder}
                firstElementId={firstElementId}
                row={row}
                tableId={tableId}
              />
              <ButtonDown
                onReorder={onReorder}
                lastElementId={lastElementId}
                row={row}
                tableId={tableId}
              />
            </>
          )}
          {onRowGetLatest && (
            <GetLatestButton
              onRowGetLatest={onRowGetLatest}
              row={row}
              tableId={tableId}
            />
          )}
        </div>
      ),
    },
  ];
};

const DataTable = <Data extends { id?: string }>({
  className,
  tableId,
  columns,
  fetchData,
  topBar,
  topBarConfig,
  getRowEditPath,
  disableRowEdit,
  onRowRemove,
  disableRowRemove,
  onReorder,
  onRowDownload,
  onRowDuplicate,
  customButton,
  onRowGetLatest,
  defaultData = [],
  gridLayout = false,
  isDeleteRequestPending = false,
  defaultSortBy,
  noActionsColumn = false,
  noTableHeader = false,
  customBodyItem,
  customLastBodyItem,
  defaultPageSize = 10,
  displayItemsNumberOptions = [10, 20, 50, 100],
  itemName = 'Rows',
  enableReordering,
  defaultPageCount,
  disableURLParams,
  hideDisplayedElementsDropdown,
  customBlankPage,
  hidePagination = false,
  areTableDependeciesLoading = false,
  dontGrowActionsColumn,
}: DataTableProps<Data>) => {
  const {
    handleURLSearchQueryParam,
    setURLParams,
    setURLSortingParams,
    unsetURLSortingParams,
  } = useURLParams();
  const { search } = useLocation();
  const [data, setData] = useState(defaultData);
  const [pageCount, setPageCount] = useState(
    defaultData ? defaultPageCount || 1 : 0
  );
  const [totalElementsCount, setTotalElementsCount] = useState(0);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [disableInitialLoad, setDisableInitialLoad] = useState(
    defaultData.length > 0
  );

  const [firstElementId, setFirstElementId] = useState<string>();
  const [lastElementId, setLastElementId] = useState<string>();

  const enableReorder =
    enableReordering && !topBarConfig?.searchFeature?.searchQuery;

  const columnsWithButtons = useMemo(
    () =>
      generateColumns<Data>(
        columns,
        onRowRemove,
        disableRowRemove,
        getRowEditPath,
        disableRowEdit,
        onReorder,
        onRowDownload,
        customButton,
        onRowGetLatest,
        tableId,
        firstElementId,
        lastElementId,
        !enableReorder,
        dontGrowActionsColumn
      ),
    [
      columns,
      onRowRemove,
      getRowEditPath,
      onReorder,
      onRowDownload,
      customButton,
      onRowGetLatest,
      tableId,
      firstElementId,
      lastElementId,
      enableReorder,
      disableRowEdit,
      disableRowRemove,
      dontGrowActionsColumn,
    ]
  );

  const getInitialPageSize = () => {
    const localPageSize = Number(
      getLocalFilter(tableId, URL_PARAMS.displayElementsNumber)
    );
    const urlPageSize = Number(getURLParam(URL_PARAMS.displayElementsNumber));
    const shouldUseLocalPageSize = !urlPageSize && localPageSize;
    const shouldUseURLPageSize = !!urlPageSize;
    if (shouldUseLocalPageSize) {
      return localPageSize;
    }
    if (shouldUseURLPageSize) {
      return urlPageSize;
    }
    return defaultPageSize;
  };

  const initialSortingParams = getInitialSorting(defaultSortBy);
  const tableInstance = useTable(
    {
      // please excuse `any` below, TS support in React Table 7 is not so great...
      columns: !noActionsColumn ? (columnsWithButtons as any) : columns,
      data,
      initialState: {
        pageIndex: +getURLParam(URL_PARAMS.pageIndex) || 0,
        pageSize: getInitialPageSize(),
        ...(initialSortingParams?.length && { sortBy: initialSortingParams }),
      },
      manualPagination: true,
      pageCount,
      autoResetRowState: false,
      autoResetPage: false,
      autoResetSortBy: false,
      manualSorting: true,
      manualSortBy: true,
    },
    useFlexLayout,
    useSortBy,
    usePagination,
    useResizeColumns
  );

  const {
    getTableProps,
    state: { pageIndex, pageSize, sortBy },
    gotoPage,
  } = tableInstance;

  useEffect(() => {
    if (!onReorder) return;
    if (pageIndex === 0) setFirstElementId(data[0]?.id);
    if (pageIndex === pageCount - 1)
      setLastElementId(data[data.length - 1]?.id);
  }, [pageIndex, pageCount, data, onReorder]);

  useEffect(() => {
    saveLocalFilter(tableId, URL_PARAMS.displayElementsNumber, pageSize);
    if (disableURLParams) return;
    const currentPageIndexInUrl = +getURLParam(URL_PARAMS.pageIndex);
    const keepHistory = pageIndex !== currentPageIndexInUrl;
    setURLParams(
      {
        [URL_PARAMS.displayElementsNumber]: `${pageSize}`,
        [URL_PARAMS.pageIndex]: `${pageIndex}`,
      },
      keepHistory
    );
  }, [disableURLParams, pageSize, pageIndex, setURLParams, tableId]);

  const prevSortBy = usePrevious(sortBy)?.[0];

  useEffect(() => {
    if (enableReorder) return;
    handleURLSearchQueryParam(topBarConfig?.searchFeature?.searchQuery);
  }, [enableReorder, handleURLSearchQueryParam, topBarConfig]);

  // Sorting from  URL handling
  useEffect(() => {
    const sortingParams = sortBy?.[0] ?? {};
    const { id, desc } = sortingParams;
    const isSortingUsed = id && typeof desc !== 'undefined';
    const userStartedSorting = !prevSortBy && isSortingUsed;
    const userIsChangingParams =
      JSON.stringify(prevSortBy) !== JSON.stringify(isSortingUsed);
    const userResignFromSorting = prevSortBy && !isSortingUsed;
    if (userStartedSorting || userIsChangingParams) {
      setURLSortingParams(sortingParams);
    }
    if (userResignFromSorting) {
      unsetURLSortingParams();
    }
  }, [prevSortBy, setURLSortingParams, sortBy, unsetURLSortingParams]);

  const setTableSortingSettingsAccordingToURLParams = useCallback((): void => {
    const params = getSortingParamsFromURL();
    tableInstance.setSortBy([params]);
  }, [tableInstance]);

  useEffect(() => {
    window.addEventListener(
      'popstate', // this event occurs whenever user is clicking back/forward button in browser
      setTableSortingSettingsAccordingToURLParams,
      false
    );
    return () =>
      window.removeEventListener(
        'popstate',
        setTableSortingSettingsAccordingToURLParams,
        false
      );
  }, [setTableSortingSettingsAccordingToURLParams]);

  const sortedColumn = sortBy?.[0] || {};
  const fetchAllData = useDebouncedCallback(
    () =>
      fetchData?.({ pageIndex, pageSize, sortedColumn })
        .then(response => {
          setData(response.data.items);
          setPageCount(Math.ceil(response.data.totalCount / pageSize));
          setTotalElementsCount(response.data.totalCount);
        })
        .catch(error => console.error(error))
        .finally(() => setIsRequestPending(false)),
    500
  );

  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    if (!fetchData) {
      return;
    }

    /**
     * We disable initial data fetch if default data is provided
     * Important None!!!: We do not add disableInitialLoad value to dependacies array.
     * This way, we do not retrigger the effect on setDisableInitialLoad - it would cause data reload.
     * Due to our current DataTable logic, that's the only way to achieve expected result.
     */
    if (disableInitialLoad) {
      setDisableInitialLoad(false);
      return;
    }

    /**
     * We are reaching for first element directly, because we re not supporting
     * multi sorting of columns. There will be likely only one column
     */
    if (!isDeleteRequestPending && !areTableDependeciesLoading) {
      setIsRequestPending(true);
      fetchAllData();
    }
    /**
     * disableInitialLoad is not added to dependecies purpously.
     * */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchData,
    pageIndex,
    pageSize,
    sortBy,
    isDeleteRequestPending,
    areTableDependeciesLoading,
  ]);

  useEffect(() => {
    if (fetchData) {
      return;
    }

    setData(defaultData);
  }, [defaultData, fetchData]);

  useEffect(() => {
    // Handle back navigation (react-router search doesn't get updated when we programmatically change the params
    // so this useEffect only gets triggered when we use the browser back/next button and the page reloads)
    const searchParams = new URLSearchParams(search);
    const pageIndexParam = searchParams.get(URL_PARAMS.pageIndex);

    if (pageIndexParam) {
      const newPageIndex = parseInt(pageIndexParam, 10);

      if (!Number.isNaN(newPageIndex)) {
        gotoPage(newPageIndex);
      }
    }
  }, [gotoPage, search]);

  const TopBarComponent = topBar ?? DataTableTopBar;

  const blankPage = customBlankPage || (
    <UIMessage
      className="no-results"
      image={NoResultsImage}
      title="No results found."
      message="Please check your search term for spelling and typos, or try removing filters."
    />
  );

  return (
    <>
      {/* You can put filtering form in top bar */}
      {topBarConfig && (
        <TopBarComponent
          topbarConfig={{
            ...topBarConfig,
            isDisabled: isRequestPending,
          }}
          pageSize={pageSize}
          pageIndex={pageIndex}
          totalElementsCount={totalElementsCount}
          tableId={tableId}
          gotoPage={gotoPage}
        />
      )}
      {!isRequestPending &&
      !areTableDependeciesLoading &&
      columns.length > 0 ? (
        <div
          className={classnames('tableWrapper', {
            'tableWrapper--grid': gridLayout,
            blankPage: data.length === 0,
          })}
        >
          <table
            className={classnames('dataTable', className, {
              'dataTable--grid': !!gridLayout,
            })}
            {...getTableProps()}
            cellPadding="10"
            data-testid={`${tableId}__table`}
          >
            {!noTableHeader ? (
              <DataTableHeader
                tableId={tableId}
                tableInstance={tableInstance}
              />
            ) : null}

            {data.length > 0 ? (
              <DataTableBody
                tableId={tableId}
                tableInstance={tableInstance}
                getRowEditPath={getRowEditPath}
                onRowRemove={onRowRemove}
                onRowDuplicate={onRowDuplicate}
                customBodyItem={customBodyItem}
                customLastBodyItem={customLastBodyItem}
                gridLayout={gridLayout}
                setData={setData}
              />
            ) : (
              blankPage
            )}
          </table>
        </div>
      ) : (
        <Loading maskContainer visible />
      )}
      {!hidePagination && (
        <DataTablePagination
          tableId={tableId}
          tableInstance={tableInstance}
          displayItemsNumberOptions={displayItemsNumberOptions}
          itemName={itemName}
          hideDisplayedElementsDropdown={hideDisplayedElementsDropdown}
          hasCustomLastBodyItem={!!customLastBodyItem}
        />
      )}
    </>
  );
};

export default DataTable;
