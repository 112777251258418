import { AxiosError, AxiosResponse } from 'axios';
import useQueryWithCallbacks from 'infrastructure/hooks/useQueryWithCallbacks';
import { getSettings } from 'infrastructure/api/endpoints/settings';
import useApi from 'infrastructure/api/useApi';
import settingsKeys from '../data/settingsKeys';
import { SettingResponse } from '../types';

const useGetAllSettings = (onError?: (error: AxiosError) => void) => {
  const { requestApi } = useApi();

  return useQueryWithCallbacks<SettingResponse>({
    queryKey: settingsKeys.allSettings,
    queryFn: () =>
      requestApi(getSettings, {
        method: 'GET',
      }).then((data: AxiosResponse<SettingResponse>) => data.data),
    onError,
  });
};

export default useGetAllSettings;
