import React, { type PropsWithChildren, type ReactElement } from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import cx from 'classnames';
import Breadcrumbs, { PathType } from '../Breadcrumbs/Breadcrumbs';

import './Content.scss';

type TabsType = {
  active?: boolean;
  url: LinkProps['to'];
  name: string;
}[];

interface ContentProps {
  /** Container classes */
  className?: string;
  /** Apply the header color highlight. Can be set with the `$header-highlight-background` SCSS variable */
  highlightHeader?: boolean;
  /** Whether the current page is a list page (with a table) */
  isListPage?: boolean;
  /** Additional page header content, can be used to replace the title if needs be */
  pageHeaderContent?: JSX.Element;
  /** An array of parent pages for Breadcrumbs */
  path?: PathType;
  /** Badge to indicate status of the page content */
  statusBadge?: ReactElement;
  /** Tabs */
  tabs?: TabsType;
  /** Title of the page */
  title?: string | JSX.Element;
  /** Vertical Alignment */
  centerContent?: boolean;
}

const Content: React.FC<PropsWithChildren<ContentProps>> = ({
  children,
  className,
  highlightHeader = false,
  isListPage = false,
  pageHeaderContent,
  path,
  statusBadge,
  tabs,
  title,
  centerContent = false,
}) => (
  <main className={cx('content-container', className)}>
    <header
      className={cx('page-header', {
        'has-breadcrumbs': !!path,
        'has-tabs': tabs && tabs.length > 1,
        highlight: highlightHeader,
      })}
    >
      <div className="header-contents">
        {path && <Breadcrumbs path={path} />}
        {title && (
          <h1 className="page-title">
            {title}
            {statusBadge && <>&nbsp;{statusBadge}</>}
          </h1>
        )}
        {pageHeaderContent}
      </div>
      {tabs && (
        <Nav tabs className="page-tabs">
          {tabs.map((tab, index) => (
            <NavItem key={`${1 + index}.url`}>
              <Link
                className={cx('h3', 'nav-link', {
                  active: tab.active,
                })}
                to={tab.url}
              >
                <span className="tab-name">{tab.name}</span>
                <div className="tab-background" />
              </Link>
            </NavItem>
          ))}
        </Nav>
      )}
    </header>

    <div
      className={cx('page-contents', {
        isListPage,
        'justify-content-start': !centerContent,
        'justify-content-center': centerContent,
      })}
    >
      {children}
    </div>
  </main>
);
export default Content;
