import React, {
  createContext,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  useState,
  type ReactNode,
} from 'react';
import { noop } from 'lodash';
import useCurrentTenant from 'infrastructure/currentTenant/useCurrentTenant';
import useGetTenantSettings from 'slices/Tenant/components/Settings/hooks/useGetTenantSettings';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';
import useGetAllSettings from 'slices/SettingsV2/hooks/useGetAllSettings';
import useGetCurrentTenant from './useGetCurrentTenant';

const TENANT_FONT_STYLE_TAG_ID = 'tenant-fonts';
const FALLBACK_FONTS =
  "-apple-system, BlinkMacSystemFont, 'Inter var', 'Inter', Arial, sans-serif";

type CurrentTenantFontContextType = {
  isLoadingTenantFont: boolean;
  loadTenantFont: () => void;
};

export const CurrentTenantFontContext =
  createContext<CurrentTenantFontContextType>({
    isLoadingTenantFont: false,
    loadTenantFont: () => {},
  });

function CurrentTenantFontProvider({ children }: { children: ReactNode }) {
  const [isLoadingTenantFont, setIsLoadingTenantFont] = useState(false);
  const {
    currentTenant: { name: tenantName },
  } = useCurrentTenant();

  const { data: settings } = useGetAllSettings(noop);
  const engagementUnitFont = settings?.engagementUnitSettings?.defaultFontUrl;
  const fontFamily =
    typeof engagementUnitFont === 'string' ? engagementUnitFont.trim() : '';

  useEffect(() => {
    if (!fontFamily) {
      return;
    }

    let css = `
    .${tenantName} {
      font-family: ${FALLBACK_FONTS};
    }`;

    let fontFormat = 'opentype';
    let fontUrl = fontFamily;

    if (fontFamily.endsWith('.woff2')) {
      fontFormat = 'woff2';
    } else if (fontFamily.endsWith('.woff')) {
      fontFormat = 'woff';
    } else if (fontFamily.endsWith('.ttf')) {
      fontFormat = 'truetype';
    } else if (fontFamily.endsWith('.otf')) {
      fontFormat = 'opentype';
    } else {
      fontUrl = `${fontFamily}.otf`;
    }

    const fontName = fontFamily.split('.')[0];

    css = `
      @font-face {
        font-family: '${fontName}';
        font-weight: 500;
        src: url('https://media.usestoryteller.com/fonts/${fontUrl}') format('${fontFormat}');
      }

      .${tenantName} {
        font-family: '${fontName}', ${FALLBACK_FONTS};
      }
    `;

    const existingStyleTag = document.querySelector(
      `style#${TENANT_FONT_STYLE_TAG_ID}`
    );

    if (existingStyleTag) {
      existingStyleTag.innerHTML = css;
      return;
    }

    const styleTag = document.createElement('style');
    styleTag.id = TENANT_FONT_STYLE_TAG_ID;
    styleTag.innerHTML = css;
    document.head.appendChild(styleTag);
  }, [fontFamily, tenantName]);

  const loadTenantFont = useCallback(async () => {
    // If the settings are loaded and there's no font family, we don't need to load anything
    if (settings && !fontFamily) {
      setIsLoadingTenantFont(false);
      return Promise.resolve();
    }

    const fontName = fontFamily.split('.')[0];
    const css = `16px '${fontName}'`;

    // Check if the font is already loaded
    if (document.fonts.check(css)) {
      setIsLoadingTenantFont(false);
      return Promise.resolve();
    }

    setIsLoadingTenantFont(true);

    await document.fonts.load(css);

    setIsLoadingTenantFont(false);

    return Promise.resolve();
  }, [fontFamily, settings]);

  const context = useMemo(
    () => ({ isLoadingTenantFont, loadTenantFont }),
    [isLoadingTenantFont, loadTenantFont]
  );

  return (
    <CurrentTenantFontContext.Provider value={context}>
      {children}
    </CurrentTenantFontContext.Provider>
  );
}

export function useCurrentTenantFont() {
  const context = useContext(CurrentTenantFontContext);

  if (!context) {
    throw new Error(
      'useCurrentTenantFont must be used within a CurrentTenantFontProvider'
    );
  }

  return context;
}

export default CurrentTenantFontProvider;
