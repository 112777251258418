import useApi from 'infrastructure/api/useApi';
import useQueryWithCallbacks from 'infrastructure/hooks/useQueryWithCallbacks';
import { contentSharingRequestsEndpoint } from 'infrastructure/api/endpoints/contentSharing';
import useContentSharing from 'slices/ContentSharing/hooks/useContentSharing';
import useCurrentUser from 'infrastructure/currentUser/useCurrentUser';

const useGetAssetRequestsCount = ({
  onSuccess,
}: {
  onSuccess: (counterValue: number) => void;
}) => {
  const { requestApi } = useApi();
  const enabled = useContentSharing();
  const { currentUser } = useCurrentUser();

  return useQueryWithCallbacks<number>({
    queryKey: ['contentSharingAssetsRequests'],
    queryFn: () =>
      requestApi(contentSharingRequestsEndpoint, {}).then(
        ({ data }) => data.totalCount
      ),
    enabled: !!enabled && !!currentUser,
    retry: false,
    /** Infitity to load only once */
    staleTime: Infinity,
    onSuccess,
  });
};

export default useGetAssetRequestsCount;
