import React, { useEffect, useRef, useState } from 'react';
import { hot, setConfig } from 'react-hot-loader';
import { QueryClientProvider, useIsFetching } from '@tanstack/react-query';
import { Router } from 'react-router-dom';
import history from 'infrastructure/history';
import UploadSummaryV2 from 'components/v2/UploadSummary';
import SignalRContextProvider from 'infrastructure/signalR/SignalRContext';
import FeatureFlagsContextProvider from 'infrastructure/features/FeatureFlagsContext';
import addFontAwesomeIconsToLibrary from 'infrastructure/icons/FontAwesomeIcons';
import UserContextProvider from 'infrastructure/currentUser/UserContext';
import AxiosContextProvider from 'infrastructure/axios/AxiosContext';
import UploadsContextProvider, {
  useUploadsContext,
} from 'infrastructure/uploads/UploadsContext';
import { AppInsightsContextProvider } from 'infrastructure/telemetry/AppInsights';
import MenuCountersContextProvider from 'infrastructure/MenuContext/MenuCountersContext';
import ScrollRestoration from 'infrastructure/scrollRestoration/ScrollRestoration';
import BlankLoadingGlobalWrapper from 'components/GlobalAppWrapper/BlankLoadingGlobalWrapper';
import CurrentTenantFontContext from 'infrastructure/currentTenant/CurrentTenantFontContext';
import AppRouter from '../../Router';
import queryClient from './queryClient';

addFontAwesomeIconsToLibrary();
setConfig({ pureSFC: true });

const nonDashboardRoutes = [
  '/',
  '/login',
  '/forgot-password',
  '/reset-password',
  '/password-reset-confirmation',
  '/error',
  '/500',
  '/404',
];

const previewRoutes = [
  '/stories/:id/preview/public',
  '/interstitialAd/:id/preview/public',
];

const matchRoute = (pathname: string, routes: Array<string>) => {
  const routeRegex = routes.map(
    route => new RegExp(`^${route.replace(/:[^/]+/g, '[^/]+')}$`)
  );
  return routeRegex.some(regex => regex.test(pathname));
};

const UploadsV2 = () => {
  const { files } = useUploadsContext();
  return <UploadSummaryV2 files={files} />;
};

const RouterWrapper: React.FC<{
  setIsInitialLoading: (value: boolean) => void;
}> = ({ setIsInitialLoading }) => {
  const isFetching = useIsFetching();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (
      (!hasMounted.current && isFetching > 0) ||
      matchRoute(window.location.pathname, nonDashboardRoutes)
    ) {
      hasMounted.current = true;
    }

    if (hasMounted.current && isFetching === 0) {
      setIsInitialLoading(false);
    }
  }, [isFetching, setIsInitialLoading]);

  return (
    <Router history={history}>
      <CurrentTenantFontContext>
        <ScrollRestoration>
          <>
            <AppRouter />
            <UploadsV2 />
          </>
        </ScrollRestoration>
      </CurrentTenantFontContext>
    </Router>
  );
};

const App: React.FC = () => {
  const { pathname } = window.location;
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const isDashboardRoute = !matchRoute(pathname, [
    ...nonDashboardRoutes,
    ...previewRoutes,
  ]);

  return (
    <>
      {isInitialLoading && (
        // This can't be wrapped inside other providers as it needs to be visible immediately
        <BlankLoadingGlobalWrapper isDashboardView={isDashboardRoute} />
      )}
      <AxiosContextProvider>
        <QueryClientProvider client={queryClient}>
          <UserContextProvider>
            <AppInsightsContextProvider>
              <FeatureFlagsContextProvider>
                <MenuCountersContextProvider>
                  <SignalRContextProvider>
                    <UploadsContextProvider>
                      <RouterWrapper
                        setIsInitialLoading={setIsInitialLoading}
                      />
                    </UploadsContextProvider>
                  </SignalRContextProvider>
                </MenuCountersContextProvider>
              </FeatureFlagsContextProvider>
            </AppInsightsContextProvider>
          </UserContextProvider>
        </QueryClientProvider>
      </AxiosContextProvider>
    </>
  );
};

export default hot(module)(App);
