import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { userInfoKeys } from './keys';
import useGetUserInfo from './useGetUserInfo';

export interface UserInfo {
  id: string;
  isSystemAdmin: boolean;
  name: string;
  roles: string[];
  tenantInfo: { defaultTimeZone: string; categoriesProvider: string };
  timedOut?: boolean;
}

interface UserContextData {
  currentUser?: UserInfo;
  isSystemAdmin?: boolean;
  isAdmin?: boolean;
  hasDeveloperPermissions?: boolean;
  hasSuperAdminPermissions?: boolean;
  hasGraphicsProducerPermissions?: boolean;
  triggerManualLogout: () => void;
  refetch: () => void;
}

const cmsRoles = {
  editor: 'editor',
  admin: 'admin',
  graphicsProducer: 'graphicsproducer',
  developer: 'developer',
  superAdmin: 'superadmin',
  systemAdmin: 'systemadmin',
};

export const UserContext = createContext<UserContextData>({} as any);

const UserContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const queryClient = useQueryClient();
  const { data: currentUser, refetch } = useGetUserInfo();

  const triggerManualLogout = useCallback(() => {
    queryClient.removeQueries({ queryKey: userInfoKeys.base });
  }, [queryClient]);

  const value = useMemo(
    () => ({
      currentUser,
      isSystemAdmin: currentUser?.isSystemAdmin,
      isAdmin:
        currentUser?.roles?.includes(cmsRoles.admin) ||
        currentUser?.roles?.includes(cmsRoles.superAdmin) ||
        currentUser?.roles?.includes(cmsRoles.systemAdmin) ||
        currentUser?.isSystemAdmin,
      hasDeveloperPermissions:
        currentUser?.roles.includes(cmsRoles.developer) ||
        !currentUser?.roles.length,
      hasSuperAdminPermissions:
        currentUser?.roles.includes(cmsRoles.superAdmin) ||
        !currentUser?.roles.length,
      hasGraphicsProducerPermissions: currentUser?.roles.includes(
        cmsRoles.graphicsProducer
      ),
      triggerManualLogout,
      refetch,
    }),
    [currentUser, triggerManualLogout, refetch]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
