/**
 * GET All Settings
 * @returns {`settings/settings`}
 */
export const getSettings = `/settings/settings`;

/**
 * PUT Category Settings
 * @returns {`settings/categorySettings`}
 */
export const editCategorySettings = `/settings/categorySettings`;

/**
 * PUT Sharing Settings
 * @returns {`settings/sharingSettings`}
 */
export const editSharingSettings = `/settings/sharingSettings`;

/**
 * PUT Login Methods
 * @returns {`settings/loginMethods`}
 */
export const editLoginMethods = `/settings/loginMethods`;

/**
 * PUT Ad Settings
 * @returns {`settings/adSettings`}
 */
export const editAdSettings = `/settings/adSettings`;

/**
 * PUT Content Caching Settings
 * @returns {`settings/contentCachingSettings`}
 */
export const editContentCachingSettings = `/settings/contentCachingSettings`;
