import { useEffect, useRef } from 'react';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

type QueryCallbackProps<QueryReturnType> = {
  onError?: (err: AxiosError) => void;
  onSuccess?: (data: QueryReturnType) => void;
};

/**
 * Wrapper around react-query's `useQuery` with support for `onError` and `onSuccess` callbacks,
 * which we used in a lot of files but were removed in v5 of react-query.
 * We should avoid using this hook in new components, and instead follow the patterns
 * recommended by react-query.
 * @see https://github.com/TanStack/query/discussions/5279
 * @deprecated
 */
function useQueryWithCallbacks<
  QueryFnReturnType, // The return type of the fetch function
  QueryReturnType = QueryFnReturnType // The return type of the query, if different
>({
  onError,
  onSuccess,
  ...queryProps
}: QueryCallbackProps<QueryReturnType> &
  UseQueryOptions<QueryFnReturnType, AxiosError, QueryReturnType>) {
  const query = useQuery<QueryFnReturnType, AxiosError, QueryReturnType>(
    queryProps
  );
  const onSuccessCallbackTriggered = useRef(false);

  useEffect(() => {
    if (query.error && onError) {
      onError(query.error);
    }
  }, [onError, query.error]);

  useEffect(() => {
    if (query.isFetching) {
      onSuccessCallbackTriggered.current = false;
    }
  }, [query.isFetching]);

  useEffect(() => {
    if (query.isSuccess && onSuccess && !onSuccessCallbackTriggered.current) {
      onSuccess(query.data);
      onSuccessCallbackTriggered.current = true;
    }
  }, [onSuccess, query.data, query.isSuccess]);

  return query;
}

export default useQueryWithCallbacks;
