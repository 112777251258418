import React, {
  useCallback,
  useRef,
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
} from 'react';
import { TableInstance } from 'react-table';
import type { SingleValue } from 'react-select';
import Pagination from './components/Pagination';

import './DataTablePagination.scss';

interface PaginationProps<Data extends object> {
  tableId: string;
  tableInstance: TableInstance<Data>;
  displayItemsNumberOptions: number[];
  itemName: string;
  hideDisplayedElementsDropdown?: boolean;
  hasCustomLastBodyItem?: boolean;
}

const DataTablePagination = <Data extends object>({
  tableId,
  tableInstance,
  displayItemsNumberOptions,
  itemName,
  hideDisplayedElementsDropdown = false,
  hasCustomLastBodyItem,
}: PaginationProps<Data>) => {
  const {
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage: goToPageIndex,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;
  const goToPageInputRef = useRef<HTMLInputElement>(null);
  const nextPageClickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      nextPage();
      (event.target as HTMLButtonElement).blur();
    },
    [nextPage]
  );

  const previousPageClickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      previousPage();
      (event.target as HTMLButtonElement).blur();
    },
    [previousPage]
  );

  const setPageSizeSelectHandler = useCallback(
    (
      tableSize: SingleValue<{
        value: string;
        label: string;
      }>
    ) => {
      setPageSize(Number(tableSize?.value));
    },
    [setPageSize]
  );

  const gotoPageInputHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const element = event.target as HTMLInputElement;
      const pageNumber = Number(element.value) || 0;
      const maximumPageNumber = pageOptions.length;
      const selectedPageNumberExceeded = pageNumber > maximumPageNumber;
      const goToPageInput = goToPageInputRef.current;
      if (goToPageInput) {
        if (selectedPageNumberExceeded) {
          goToPageInput.value = String(maximumPageNumber);
        }
      }
    },
    [pageOptions.length]
  );

  const onBlurHandler = useCallback(
    (
      event: ChangeEvent<HTMLInputElement> | KeyboardEvent<HTMLInputElement>
    ) => {
      const element = event.target as HTMLInputElement;
      const pageNumber = Number(element.value) || 0;
      const isPageNumberNegative = pageNumber <= 0;
      if (isPageNumberNegative) {
        element.value = String(1); // Assign minimal correct value to input
        goToPageIndex(0); // Go to first available page
      } else {
        goToPageIndex(pageNumber - 1); // If page number is correct, go to selected page
      }
    },
    [goToPageIndex]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onBlurHandler(event);
      }
    },
    [onBlurHandler]
  );

  const handleOnInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^0-9]+/g, '');
    },
    []
  );

  return (
    <Pagination
      tableId={tableId}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageOptions={pageOptions}
      gotoPageInputHandler={gotoPageInputHandler}
      onBlurHandler={onBlurHandler}
      handleKeyDown={handleKeyDown}
      handleOnInput={handleOnInput}
      itemName={itemName}
      hideDisplayedElementsDropdown={hideDisplayedElementsDropdown}
      hasCustomLastBodyItem={hasCustomLastBodyItem}
      canPreviousPage={canPreviousPage}
      canNextPage={canNextPage}
      nextPageClickHandler={nextPageClickHandler}
      previousPageClickHandler={previousPageClickHandler}
      setPageSizeSelectHandler={setPageSizeSelectHandler}
      displayItemsNumberOptions={displayItemsNumberOptions}
      goToPageInputRef={goToPageInputRef}
    />
  );
};

export default DataTablePagination;
