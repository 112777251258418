import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import variables from 'scss/1-settings/colors.scss';
import { ClearIndicatorProps, GroupBase } from 'react-select';
import Button from 'components/Button';
import type { SelectFieldOptionObject } from '../SelectField/types';

import './SelectFieldCustomClearIndicator.scss';

const SelectFieldCustomClearIndicator = <
  Option extends SelectFieldOptionObject,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  innerProps,
  redirectUrl,
}: ClearIndicatorProps<Option, IsMulti, Group> & { redirectUrl?: string }) => (
  <div
    style={{ fontSize: 16, zIndex: 1 }}
    className={cx({ 'select-field-clear-indicator': !!redirectUrl })}
  >
    {redirectUrl && (
      <Button
        className="select-field-link-button"
        onMouseDown={e => {
          e.stopPropagation();
          window.location.href = redirectUrl;
        }}
        icon={<FontAwesomeIcon icon={faLink} color={variables['gray-400']} />}
      />
    )}

    <FontAwesomeIcon
      icon={faTimesCircle}
      color={variables['gray-400']}
      {...innerProps}
    />
  </div>
);

export default SelectFieldCustomClearIndicator;
