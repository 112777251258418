import React from 'react';
import Loadable from 'react-loadable';
import { Loading } from 'components';

export default Loadable({
  loader: () => import('./ClipsIndex'),
  loading: () => <Loading withContentWrapper />,
});

export const List = Loadable({
  loader: () => import('./containers/ClipsList/ClipsList'),
  loading: () => <Loading withContentWrapper />,
});

export const Form = Loadable({
  loader: () => import('./containers/ClipsFormPage/ClipsFormPage'),
  loading: () => <Loading withContentWrapper />,
});

export const Analytics = Loadable({
  loader: () => import('./containers/ClipsAnalyticsPage/ClipsAnalyticsPage'),
  loading: () => <Loading withContentWrapper />,
});

export const Captions = Loadable({
  loader: () => import('./containers/ClipsCaptionsPage/ClipsCaptionsPage'),
  loading: () => <Loading withContentWrapper />,
});
