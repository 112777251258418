import { QueryCache, QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.errorAction) {
        (query.meta.errorAction as Function)();
      }
    },
    onSuccess: (data, query) => {
      if (query.meta?.successAction) {
        (query.meta.successAction as Function)(data);
      }
    },
  }),
});

export default queryClient;
