import React, { useCallback } from 'react';
import { Input, Label } from 'reactstrap';
import cx from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { debounce } from 'shared/helpers/debounce';

interface SearchProps {
  searchQuery?: string;
  setSearchQuery: (query: string) => void;
  testId: string;
  isDisabled?: boolean;
  className?: string;
  gotoPage?: (page: number) => void;
}

const SearchField: React.FC<SearchProps> = ({
  searchQuery = '',
  setSearchQuery,
  testId,
  isDisabled,
  className,
  gotoPage,
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchQuery(event.target.value);
    gotoPage?.(0);
  };

  const debouncedChangeHandler = useCallback(debounce(handleChange), []);

  return (
    <form
      className={cx('form search-bar', className)}
      onSubmit={event => {
        event.preventDefault();
      }}
    >
      <div className="form__form-group-field">
        <Label className="sr-only" htmlFor="tableSearch">
          Search
        </Label>
        <Input
          className="search-input"
          id="tableSearch"
          name="tableSearch"
          placeholder="Search"
          type="search"
          defaultValue={searchQuery}
          onChange={event => {
            event.persist();
            debouncedChangeHandler(event);
          }}
          data-testid={`${testId}__searchInput`}
          disabled={isDisabled}
        />
        <Button
          aria-label="Search"
          type="button"
          className="input-group-append"
          color="secondary"
          icon={<FontAwesomeIcon icon={faSearch} />}
          disabled={isDisabled}
        />
      </div>
    </form>
  );
};

export default SearchField;
